/* eslint-disable max-lines */
import { schema } from 'normalizr';
import paginateAction from '@utils/paginateAction';
import { myLibrarySourceType, languageTypes } from '@constants/index';
import { courseTypes, itemTypes, plusCoursesTypes } from '../constants';
import { paginatorListSchema, courseSchema, itemSchema } from '../schemas';

const getFreeGoalCourses = (goalUid, resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_FREE_GOAL_COURSES,
  endpoint: next,
  method: 'get',
  key: goalUid,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'course'
});

export const fetchFreeGoalCourses = (
  goalUid,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    `v1/topology/users/${goalUid}/courses/?&limit=25`,
    getFreeGoalCourses(goalUid, resetWithoutEmpty),
    'freeGoalCourses',
    goalUid,
    resetWithoutEmpty
  )(nextPage);

export const fetchCourseData = (courseUid) => (dispatch) =>
  dispatch({
    type: courseTypes.FETCH_COURSE_INFO,
    endpoint: `v1/collection/${courseUid}/`,
    method: 'get',
    courseUid,
    schema: new schema.Object({ course: courseSchema }),
    entityType: 'course',
    // this processData will be called in the createApiMiddleWare with the above courseSchema
    processData: (data) => {
      const { languages: newLanguages = [] } = data;

      const voiceLanguages = newLanguages.filter(
        (lang) => lang.language_type === languageTypes.SPOKEN
      );
      const contentSlideLanguages = newLanguages.filter(
        (lang) => lang.language_type === languageTypes.CONTENT_SLIDE_LANGUAGE
      );
      return {
        ...data,
        ...data.programme,
        voiceLanguages,
        contentSlideLanguages
      };
    }
  });

const getFreeContinueLearningCourses = (resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_FREE_CONTINUE_LEARNING,
  endpoint: next,
  method: 'get',
  key: 'continue_learning',
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'course'
});

export const fetchFreeContinueLearningCourses = (
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    'v1/user/me/continue_watching/',
    getFreeContinueLearningCourses(resetWithoutEmpty),
    'continueLearning',
    'continue_learning',
    resetWithoutEmpty
  )(nextPage);

const getLibrary = (key, resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_MY_LIBRARY,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: key === myLibrarySourceType.COURSE ? 'course' : 'item'
});

export const fetchLibrary = (key, nextPage, resetWithoutEmpty = false) =>
  paginateAction(
    `v1/users/bookmark/?limit=10&target_type=${key}`,
    getLibrary(key, resetWithoutEmpty),
    'library',
    key,
    resetWithoutEmpty
  )(nextPage);

const getWatchHistory = (username, resetWithoutEmpty) => (next) => ({
  type: itemTypes.FETCH_WATCH_HISTORY,
  endpoint: next,
  method: 'get',
  key: username,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'item'
});

export const fetchWatchHistory = (
  username,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    'v1/post/watch_history/?limit=10',
    getWatchHistory(username, resetWithoutEmpty),
    'watchHistory',
    username,
    resetWithoutEmpty
  )(nextPage);

export const toggleSaved = (courseUid, save) => ({
  type: courseTypes.TOGGLE_COURSE_SAVED,
  courseUid,
  save
});

export const fetchEnrolledCoursesFilters = (goalId) => (dispatch) =>
  dispatch({
    type: courseTypes.FETCH_ENROLLED_COURSES_FILTERS,
    endpoint: `v1/uplus/subscription/user_enroll_filters/?goal_uid=${goalId}`,
    method: 'get'
  });

const getEnrolledCourses = (key, resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_ENROLLED_COURSES,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'course'
});

export const fetchEnrolledCourses = (
  goalUid,
  filterObj = {},
  nextPage,
  resetWithoutEmpty = false
) => {
  const { state, language, educator, topic } = filterObj;
  const key = `${goalUid}-enrolled-${state}-${language}-${educator}-${topic}`;
  return paginateAction(
    `v1/uplus/subscription/enrolled_courses/?goal_uid=${goalUid}&v=2&limit=12&test_series=0&filters=${encodeURIComponent(
      JSON.stringify({
        state,
        language: language === 'all' || !language ? [] : [language],
        educator: educator === 'all' || !educator ? [] : [educator],
        topic: topic === 'all' || !topic ? [] : [topic]
      })
    )}`,
    getEnrolledCourses(key, resetWithoutEmpty),
    'enrolledCourses',
    key,
    resetWithoutEmpty
  )(nextPage);
};

export const toggleEnrolledCourse =
  (courseUID, enroll, send_email = false, eventKey) =>
  (dispatch) =>
    dispatch({
      type: courseTypes.TOGGLE_COURSE_ENROLLED,
      endpoint: `v2/uplus/programmes/${courseUID}/save/`,
      method: 'post',
      courseUID,
      enroll,
      schema: new schema.Object({}),
      data: { enroll, send_email, prodigy_key: eventKey }
    });

export const addCourseToPlanner =
  (
    courseUID,
    daily_item_schedule_at,
    include_completed_lessons = true,
    days = [0, 1, 2, 3, 4, 5, 6],
    start_date
  ) =>
  (dispatch) => {
    const today = new Date();
    today.setHours(0, 0, 0);
    const startDateInstance = start_date ? new Date(start_date) : new Date();
    startDateInstance.setHours(0, 0, 0);
    startDateInstance.setHours(0, 0, 0);
    const startDate = startDateInstance.getTime();
    dispatch({
      type: courseTypes.ADD_COMPLETED_COURSE_TO_PLANNER,
      endpoint: `v1/planner/courses/${courseUID}/`,
      method: 'post',
      courseUID,
      plannerSchedule: {
        daily_item_schedule_at,
        start_date: startDate,
        is_scheduled: true,
        include_completed_lessons,
        week_days: days
      },
      schema: new schema.Object({}),
      data: {
        daily_item_schedule_at,
        include_completed_lessons,
        start_date: startDate,
        days
      }
    });
  };

export const removeCourseFromPlanner = (courseUID) => (dispatch) => {
  dispatch({
    type: courseTypes.REMOVE_COURSE_FROM_PLANNER,
    endpoint: `v1/planner/courses/${courseUID}/`,
    method: 'delete',
    courseUID,
    schema: new schema.Object({})
  });
};

export const getPlannerSchedule = (courseUID) => (dispatch) => {
  dispatch({
    type: courseTypes.GET_PLANNER_SCHEDULE,
    endpoint: `v1/uplus/programmes/${courseUID}/details/?v=2`,
    method: 'get',
    courseUID,
    schema: new schema.Object({})
  });
};

export const getFreeLessonDetails = (lessonUID) => (dispatch) =>
  dispatch({
    type: itemTypes.FETCH_FREE_LESSON_INFO,
    endpoint: `v1/post/${lessonUID}/`,
    method: 'get',
    schema: new schema.Object({ item: itemSchema }),
    entityType: 'item',
    uid: lessonUID
  });

export const getFreeLessonListFromLessonUID = (lessonUID) => (dispatch) =>
  dispatch({
    type: itemTypes.FETCH_FREE_LESSON_LIST,
    endpoint: `v2/post/${lessonUID}/related_items/`,
    method: 'get',
    directPaginatedEntity: true,
    schema: paginatorListSchema,
    entityType: 'item',
    processData: (data) => {
      const newData = data;
      newData.results = newData.results.map((resultItem) => {
        return {
          ...resultItem,
          properties: {
            ...resultItem.value,
            is_bookmarked: resultItem.is_bookmarked
          }
        };
      });
      return newData;
    },
    uid: lessonUID
  });

export const toggleBookmarkItem = (uid, type, toBookmark) => (dispatch) => {
  let url = 'v1/users/bookmark/';
  if (!toBookmark) url = 'v1/users/bookmark/delete/';
  return dispatch({
    type: itemTypes.BOOKMARK_ITEM,
    endpoint: url,
    method: 'post',
    data: {
      target: uid,
      target_type: type
    },
    uid,
    isBookmarked: toBookmark
  });
};

const getLessonList = (uid, resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_FREE_COURSE_ITEM,
  endpoint: next,
  method: 'get',
  key: uid,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'item',
  processData: (data) => {
    const newData = data;
    newData.results = data.results.map((resultItem) => {
      const resultData = {
        ...resultItem.value.live_class,
        ...resultItem.value,
        ...resultItem
      };
      resultData.live_class2 = { ...resultItem.value.live_class };
      delete resultData.live_class;
      delete resultData.collection;
      delete resultData.value;
      return resultData;
    });
    return newData;
  }
});

export const fetchLessonList = (
  uid,
  getExtraInfo = false,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    `v2/collection/${uid}/items/?limit=600${
      getExtraInfo ? '&extra_info=True' : ''
    }`,
    getLessonList(uid, resetWithoutEmpty),
    'courseItems',
    uid,
    resetWithoutEmpty
  )(nextPage);

export const fetchRelevantCourses = (courseUID) => (dispatch) =>
  dispatch({
    type: courseTypes.FETCH_RELEVANT_COURSES,
    endpoint: `v1/collection/${courseUID}/relevant_courses/`,
    method: 'get',
    courseUID,
    schema: new schema.Array({ course: courseSchema }),
    entityType: 'course',
    processData: (data) => data.slice(0, 4)
  });

const getCourseReviews = (courseUID, resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_COURSE_REVIEWS,
  endpoint: next,
  method: 'get',
  key: courseUID,
  resetWithoutEmpty,
  schema: new schema.Object({}),
  processData: (data) => {
    const newData = data;
    newData.results = newData.results.map((resultItem) => ({
      review: resultItem.review,
      rating: resultItem.rating,
      createdAt: resultItem.created_at,
      username: resultItem.user.username,
      name: `${resultItem.user.first_name} ${resultItem.user.last_name}`,
      avatar: resultItem.user.avatar
    }));
    return newData;
  }
});

export const fetchCourseReviews = (
  courseUID,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    `v1/collection/${courseUID}/reviews/?limit=3`,
    getCourseReviews(courseUID, resetWithoutEmpty),
    'courseReviews',
    courseUID,
    resetWithoutEmpty
  )(nextPage);

export const submitCourseReview = (review, rating, courseUID) => (dispatch) =>
  dispatch({
    type: courseTypes.SUBMIT_REVIEW,
    endpoint: `v1/collection/${courseUID}/review/`,
    method: 'put',
    data: {
      review,
      rating
    }
  });

const getFreeCoursesOfAGoal = (goalUid, resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_FREE_COURSES_OF_A_GOAL,
  endpoint: next,
  method: 'get',
  key: goalUid,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'feedrow',
  processData: (data) => {
    const newData = data;
    newData.results = newData.results.map((resultItem) => ({
      ...resultItem,
      key: `${goalUid}_free`
    }));
    return newData;
  }
});

export const fetchFreeCoursesOfAGoal = (
  goalUid,
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    `v1/feeds/user/home_v2/?goal_uid=${goalUid}`,
    getFreeCoursesOfAGoal(goalUid, resetWithoutEmpty),
    'freeCoursesFeed',
    goalUid,
    resetWithoutEmpty
  )(nextPage);

export const getSubscriptionOnboardingRecommendedCourses =
  (goalUid, goalSlug, source) => (dispatch) =>
    dispatch({
      type: courseTypes.FETCH_ONBOARDING_RECOMMENDED_COURSES,
      endpoint: `v1/uplus/subscription/course-card/?goal_uid=${goalUid}&source=${source}`,
      method: 'get',
      goalUid,
      schema: { data: [{ course: courseSchema }] },
      processData: (data) => {
        const newData = data;
        newData.data = newData.data.map((dataItem) => ({
          course: {
            ...dataItem,
            goal: {
              uid: goalUid,
              goalSlug
            }
          }
        }));
        return newData;
      }
    });

const getPreviousEnrolledCourses = (key, resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_PREVIOUS_ENROLLED_COURSES,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'course'
});

export const fetchPreviousEnrolledCourses = (
  nextPage,
  resetWithoutEmpty = false
) =>
  paginateAction(
    'v1/uplus/live_programmes_v3/enrolled/?limit=12&send_special_test_series=0&subscriptions=0&special=0',
    getPreviousEnrolledCourses('prevEnrolledCourses', resetWithoutEmpty),
    'prevEnrolledCourses',
    'prevEnrolledCourses',
    resetWithoutEmpty
  )(nextPage);

const getCourseUpdates = (channelId, resetWithoutEmpty) => (next) => ({
  type: plusCoursesTypes.FETCH_COURSE_UPDATES,
  endpoint: next,
  method: 'get',
  key: channelId,
  resetWithoutEmpty
});

export const fetchCourseUpdates = (
  channelId,
  nextPage,
  resetWithoutEmpty = false,
  isBatch = false,
  filters
) => {
  let url = `v2/channel/${channelId}/stories/?limit=7`;
  if (isBatch) {
    url = `v2/channel/batch/${channelId}/batch_stories/?limit=7`;
  }
  if (filters) url += `&filters=${encodeURIComponent(JSON.stringify(filters))}`;

  return paginateAction(
    url,
    getCourseUpdates(channelId, resetWithoutEmpty),
    'courseUpdates',
    channelId,
    resetWithoutEmpty
  )(nextPage);
};

const getComments = (id, resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_COMMENTS,
  endpoint: next,
  method: 'get',
  key: id,
  resetWithoutEmpty
});

export const fetchComments = (id, nextPage, resetWithoutEmpty = false) =>
  paginateAction(
    `v1/story/${id}/comments/?limit=10`,
    getComments(id, resetWithoutEmpty),
    'courseUpdateComments',
    id,
    resetWithoutEmpty
  )(nextPage);

export const toggleLike = (channelId, id, isLiked) => (dispatch) =>
  dispatch({
    type: courseTypes.TOGGLE_LIKE,
    endpoint: isLiked ? `v1/story/${id}/unreact/` : `v1/story/${id}/react/`,
    method: 'post',
    key: channelId,
    id,
    isLiked,
    data: {
      type: isLiked ? 0 : 1
    }
  });

export const toggleCommentLike = (key, id, isLiked) => (dispatch) =>
  dispatch({
    type: courseTypes.TOGGLE_COMMENT_LIKE,
    endpoint: isLiked
      ? `v1/story/comment/${id}/delete_upvote/`
      : `v1/story/comment/${id}/upvote/`,
    method: 'post',
    key,
    id,
    isLiked,
    data: {
      type: isLiked ? 0 : 1
    }
  });

export const addComment = (id, text) => (dispatch) =>
  dispatch({
    type: courseTypes.ADD_COMMENT,
    endpoint: `v1/story/${id}/comment/`,
    method: 'post',
    key: id,
    id,
    data: { text }
  });

export const addReply = (key, id, text) => (dispatch) =>
  dispatch({
    type: courseTypes.ADD_REPLY,
    endpoint: `v1/story/comment/${id}/comment/`,
    method: 'post',
    key,
    id,
    data: { text }
  });

export const adjustCommentNumber = (channelId, id) => ({
  type: courseTypes.INCREASE_COMMENT,
  key: channelId,
  id
});

export const fetchUpdateFilters = (channelId) => (dispatch) =>
  dispatch({
    type: courseTypes.FETCH_FILTERS,
    endpoint: `v1/channel/${channelId}/filters/`,
    method: 'get'
  });

export const updateFilters = (filter, kind, reset) => ({
  type: courseTypes.UPDATE_FILTER,
  filter,
  reset,
  kind
});

export const fetchMoreCoursesFilters = (goalUID) => (dispatch) => {
  const url = `v1/uplus/syllabus/course_filters/?goal_uid=${goalUID}`;
  return dispatch({
    type: courseTypes.FETCH_MORE_COURSES_FILTERS,
    endpoint: url,
    method: 'get',
    goalUID
  });
};

const getMoreCourses =
  ({ key, resetWithoutEmpty = false }) =>
  (next) => ({
    type: courseTypes.FETCH_MORE_COURSES,
    endpoint: next,
    method: 'get',
    key,
    resetWithoutEmpty,
    schema: paginatorListSchema,
    directPaginatedEntity: true,
    entityType: 'course',
    processData(data) {
      return {
        ...data,
        results: data.results?.map((course) => course.programme || course)
      };
    }
  });

export const fetchMoreCourses = ({
  topicUid,
  nextPage = false,
  resetWithoutEmpty = false,
  filterParams = '',
  key
}) =>
  paginateAction(
    `v1/uplus/syllabus/more_courses/?topology_uid=${topicUid}&limit=9${filterParams}`,
    getMoreCourses({ key, resetWithoutEmpty }),
    'moreCourses',
    key,
    resetWithoutEmpty
  )(nextPage);

const getCompletedCourses = (key, resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_COMPLETED_COURSES,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'course',
  processData(data) {
    return {
      ...data,
      results: data.results?.map((course) => course.programme || course)
    };
  }
});

export const fetchCompletedCourses = (
  topicUid,
  nextPage,
  resetWithoutEmpty = false,
  params = ''
) =>
  paginateAction(
    `v1/uplus/syllabus/completed_courses/?topology_uid=${topicUid}&limit=6${params}`,
    getCompletedCourses(topicUid, resetWithoutEmpty),
    'completedCourses',
    topicUid,
    resetWithoutEmpty
  )(nextPage);

const getRecommendedCourses = (key, resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_RECOMMENDED_COURSES,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'course',
  processData(data) {
    return {
      ...data,
      results: data.results?.map((course) => course.programme || course)
    };
  }
});

export const fetchRecommendedCourses = (
  topicUid,
  nextPage,
  resetWithoutEmpty = false,
  params = ''
) =>
  paginateAction(
    `v1/uplus/syllabus/courses_for_you/?topology_uid=${topicUid}${params}`,
    getRecommendedCourses(topicUid, resetWithoutEmpty),
    'recommendedCourses',
    topicUid,
    resetWithoutEmpty
  )(nextPage);

const getTopologyEnrolledCourses = (key, resetWithoutEmpty) => (next) => ({
  type: courseTypes.FETCH_ENROLLED_COURSES,
  endpoint: next,
  method: 'get',
  key,
  resetWithoutEmpty,
  schema: paginatorListSchema,
  directPaginatedEntity: true,
  entityType: 'course',
  processData(data) {
    return {
      ...data,
      results: data.results?.map((course) => course.programme || course)
    };
  }
});

export const fetchTopologyEnrolledCourses = (
  topicUid,
  nextPage,
  resetWithoutEmpty = false,
  params = '',
  limit = 3
) =>
  paginateAction(
    `v1/uplus/syllabus/enrolled_courses/?topology_uid=${topicUid}&limit=${limit}${params}`,
    getTopologyEnrolledCourses(topicUid, resetWithoutEmpty),
    'enrolledCourses',
    topicUid,
    resetWithoutEmpty
  )(nextPage);

export const checkCoursesEnrollStatus = (courses) => (dispatch) =>
  dispatch({
    type: courseTypes.CHECK_COURSE_ENROLL,
    method: 'get',
    endpoint: `v1/uplus/enroll/status?programme_uids=${courses.toString()}`
  });
