import { Provider } from 'react-redux';
import { getStore } from 'src/redux';
import PageReduxContextWrapper from '@context/IsPageInReduxContext';

const PageReduxProvider = ({ children, initialReduxState }) => {
  const store = getStore(initialReduxState);
  return (
    <PageReduxContextWrapper store={store}>
      <Provider store={store}>{children}</Provider>
    </PageReduxContextWrapper>
  );
};

export default PageReduxProvider;
